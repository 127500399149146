<template>
    <div v-loading="loading" class="activity">
        <div class="userTitle">
            <span>{{ $t('huo-dong-xiang-qing') }}</span>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">{{ $t('shou-ye') }}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: adminPath }">{{ $t('ge-ren-zhong-xin') }}</el-breadcrumb-item>

                <el-breadcrumb-item :to="{ path: '/mineActivity' }">{{ $t('wo-de-huo-dong') }}</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $t('huo-dong-xiang-qing') }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="main">
            <div class="info">
                <div class="img">
                    <auto-img :src="information.img" mul="70%"></auto-img>
                </div>

                <div class="info-main">
                    <div class="title">{{ information.title }}</div>

                    <div class="sub" v-if="information.type !== 'INDUSTRY_ACTIVITIES'">
                        {{ information.introduction }}
                    </div>

                    <template v-else>
                        <div class="info-item" style="margin-top: 24px">
                            <div class="text1">{{ $t('shi-jian') }}：</div>
                            <div class="text2">{{ information.date }}</div>
                        </div>
                        <div class="info-item">
                            <div class="text1">{{ $t('di-zhi') }}：</div>
                            <div class="text2">{{ information.address }}</div>
                        </div>
                        <div class="info-item">
                            <div class="text1">{{ $t('chang-ci') }}：</div>
                            <div class="text2">
                                <el-tag
                                    size="mini"
                                    effect="plain"
                                    type="info"
                                    v-for="(item, index) in information.session"
                                    :key="index"
                                    >{{ item }}</el-tag
                                >
                            </div>
                        </div>

                        <el-button :type="isEnd ? 'info' : 'warning'" :disabled="isEnd" class="btn" @click="takeForm">{{
                            isEnd ? $t('bao-ming-jie-shu') : $t('cha-kan-bao-ming-xin-xi')
                        }}</el-button>
                    </template>
                </div>
            </div>

            <div class="detail" v-html="information.content"></div>
        </div>

        <Submit :info="information" :activityId.sync="activityId" ref="submit" />
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { InformationType } from '../../utils/AppState';
import Submit from '../../components/activity/Submit';
export default {
    name: 'NewsDetail',
    data() {
        return {
            loading: false,
            information: {},
            from: '',
            activityId: 0
        };
    },
    computed: {
        typeInfo() {
            if (this.information.type) {
                return InformationType.get(this.information.type);
            }
            return {
                name: ''
            };
        },
        isEnd() {
            return this.information.isEnd || false;
        }
    },
    watch: {
        activityId() {
            if (!this.activityId) {
                this.$router.go(-1);
            }
        }
    },
    mounted() {
        this.activityId = this.$route.query.informationId;
        this.loading = true;
        this.$http.get('/information/get/' + this.activityId).then(res => {
            this.loading = false;
            this.information = res;
        });
    },
    methods: {
        takeForm() {
            this.$refs.submit.showForm();
        }
    },
    components: {
        Submit
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
.userTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main {
    padding: 30px 80px;
}
.info {
    display: flex;
    padding: 30px 0;

    .img {
        width: 390px;
        flex-shrink: 0;

        .autoImg {
            cursor: inherit;
        }
    }

    .info-main {
        padding: 10px 0 10px 24px;
        flex-grow: 1;

        .title {
            font-size: 24px;
            color: #000000;
            line-height: 33px;
            font-weight: bold;
        }

        .sub {
            font-size: 14px;
            color: #565b66;
            line-height: 24px;
            margin-top: 14px;
        }
    }
}

.info-item {
    display: flex;
    align-items: center;
    margin-top: 12px;

    .text1 {
        font-size: 12px;
        color: #bcc1cc;
        line-height: 22px;
    }

    .text2 {
        font-size: 12px;
        color: #565b66;
        line-height: 22px;
        margin-left: 10px;

        .el-tag {
            margin-right: 10px;
            border-radius: 0px;
        }
    }
}

.btn {
    margin-top: 40px;
    min-width: 250px;
}
</style>
